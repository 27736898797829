import React, { useState, useEffect } from 'react';
import './App.css';
import ScoreDashboard from './ScoreDashboard';  // ScoreDashboard for tracking scores
import LoginModal from './LoginModal'; // Import the login modal

function App() {
    const [token, setToken] = useState(localStorage.getItem('token'));  // Store JWT token
    const [isLoginVisible, setIsLoginVisible] = useState(false);  // Show login modal
    const [presetPrompts] = useState([
        "The US Government Should Ban Smoking Cigarettes",
        "Social Media Does More Harm than Good",
        "Climate Change is the Greatest Threat to Humanity",
        "Artificial Intelligence Will Do More Harm than Good",
        "School Uniforms Should be Mandatory",
        "The Voting Age Should Be Lowered to 16",
        "Public Transportation Should Be Free",
        "All Drugs Should Be Legalized",
        "Electric Cars are the Future",
        "Homework Should be Abolished",
        "College Education Should Be Free",
        "Zoos Should Be Banned",
        "The Death Penalty Should Be Abolished",
        "Social Media Influencers Have a Negative Impact on Society",
        "Video Games Contribute to Violent Behavior",
        "There Should Be Stricter Gun Control Laws",
        "Technology is Making People Less Social",
        "Online Learning is the Future of Education",
        "The World Would Be Better Without Borders",
        "Genetic Engineering Should Be Allowed in Humans"
    ]);

    const [prompt, setPrompt] = useState(presetPrompts[0]);  // Default prompt
    const [customPrompt, setCustomPrompt] = useState('');  // For user input
    const [recording, setRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [audioBlob, setAudioBlob] = useState(null);
    const [transcription, setTranscription] = useState('');
    const [timer, setTimer] = useState(60);  // Default to 1 minute (60 seconds)
    const [countdown, setCountdown] = useState(timer);  // Track remaining time
    const [isLoading, setIsLoading] = useState(false);  // Loading state for transcription
    const [error, setError] = useState('');  // Error state

    useEffect(() => {
        setCountdown(timer);
    }, [timer]);

    const startRecording = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const recorder = new MediaRecorder(stream);

        recorder.ondataavailable = event => setAudioBlob(event.data);

        recorder.start();
        setMediaRecorder(recorder);
        setRecording(true);

        const countdownInterval = setInterval(() => {
            setCountdown(prev => {
                if (prev === 1) {
                    clearInterval(countdownInterval);
                    recorder.stop();
                    setRecording(false);
                }
                return prev - 1;
            });
        }, 1000);

        setTimeout(() => {
            clearInterval(countdownInterval);
            recorder.stop();
            setRecording(false);
        }, timer * 1000);
    };

    const stopRecording = () => {
        mediaRecorder.stop();
        setRecording(false);
    };

    const transcribeAudio = async () => {
        const formData = new FormData();
        formData.append('audio', audioBlob, 'audio.webm');

        setIsLoading(true);
        setError('');

        try {
            const response = await fetch('http://localhost:5000/api/transcribe', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
                },
                body: formData,
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(errorText);
            }

            const data = await response.json();
            setTranscription(data.transcription);
        } catch (error) {
            setError(`Error during transcription: ${error.message}`);
            console.error('Error during transcription:', error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePromptChange = (event) => {
        const selectedPrompt = event.target.value;
        setPrompt(selectedPrompt);
    };

    const handleCustomPromptChange = (event) => {
        setCustomPrompt(event.target.value);
    };

    const handleCustomPromptSubmit = (event) => {
        event.preventDefault();
        if (customPrompt.trim()) {
            setPrompt(customPrompt);
            setCustomPrompt('');  // Reset the input field
        }
    };

    return (
        <div className="app-container">
            <header className="app-header">
                <h1>Public Speaking Training</h1>
                {!token && (
                    <button
                        className="login-button"
                        onClick={() => setIsLoginVisible(true)}  // Show the login modal
                    >
                        Log In to Save/View Scores
                    </button>
                )}
            </header>
            <main className="app-main">
                {isLoginVisible && <LoginModal setIsLoginVisible={setIsLoginVisible} setToken={setToken} />}  {/* Show login modal */}

                {/* Debate Prompt Section */}
                <div className="prompt-container">
                    <h2>Debate Prompt</h2>
                    <p className="prompt-text">{prompt}</p>
                    <div className="prompt-selector" data-tooltip={prompt}>
                        <select onChange={handlePromptChange}>
                            {presetPrompts.map((debatePrompt, index) => (
                                <option key={index} value={debatePrompt}>{debatePrompt}</option>
                            ))}
                        </select>
                    </div>

                    <form onSubmit={handleCustomPromptSubmit}>
                        <div className="custom-prompt-input-wrapper" data-tooltip={customPrompt}>
                            <input
                                type="text"
                                placeholder="Or enter your own prompt..."
                                value={customPrompt}
                                onChange={handleCustomPromptChange}
                                className="custom-prompt-input"
                            />
                        </div>
                        <button type="submit">Add Prompt</button>
                    </form>
                </div>

                {/* Timer and Recording Section */}
                <div className="timer-options">
                    <label>Choose your speech time:</label>
                    <select onChange={(e) => setTimer(parseInt(e.target.value))} value={timer}>
                        <option value="60">1 Minute</option>
                        <option value="180">3 Minutes</option>
                        <option value="300">5 Minutes</option>
                    </select>
                </div>

                <div className="recording-container">
                    <div className="recording-buttons">
                        <button onClick={startRecording} disabled={recording}>
                            Start Recording
                        </button>
                        <button onClick={stopRecording} disabled={!recording}>
                            Stop Recording
                        </button>
                    </div>
                    {recording && (
                        <div className="countdown">
                            Time remaining: {countdown} seconds
                        </div>
                    )}
                    {audioBlob && (
                        <div className="audio-playback">
                            <h3>Playback your Recording</h3>
                            <audio controls src={URL.createObjectURL(audioBlob)} />
                            <button onClick={transcribeAudio}>
                                Transcribe Audio
                            </button>
                        </div>
                    )}
                    {isLoading && <div className="loading-spinner">Processing...</div>}
                    {error && <div className="error-message">{error}</div>}
                    {transcription && (
                        <div className="transcription-result">
                            <h3>Transcription:</h3>
                            <p>{transcription}</p>
                        </div>
                    )}
                </div>

                {/* Show Score Dashboard only if user is logged in */}
                {token && (
                    <ScoreDashboard token={token} />  // Show the dashboard if logged in
                )}
            </main>
            <footer className="app-footer">
                <p>&copy; 2024 Vasuman. All rights reserved.</p>
            </footer>
        </div>
    );
}

export default App;
