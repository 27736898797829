import React, { useEffect, useState } from 'react';

const ScoreDashboard = ({ token }) => {
    const [scores, setScores] = useState([]);
    const [newScore, setNewScore] = useState({
        prompt: '',
        oneMinute: '',
        threeMinute: '',
        fiveMinute: ''
    });

    useEffect(() => {
        // Fetch user scores from the backend
        const fetchScores = async () => {
            const response = await fetch('http://localhost:5000/api/auth/scores', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const data = await response.json();
            setScores(data);
        };
        fetchScores();
    }, [token]);

    // Handle form submission to submit new score
    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await fetch('http://localhost:5000/api/auth/submit-score', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(newScore),
        });
        const data = await response.json();
        if (data.message) {
            alert(data.message);
            setNewScore({ prompt: '', oneMinute: '', threeMinute: '', fiveMinute: '' });
            // Fetch updated scores after submission
            const updatedResponse = await fetch('http://localhost:5000/api/auth/scores', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const updatedData = await updatedResponse.json();
            setScores(updatedData);
        } else {
            alert('Failed to submit score');
        }
    };

    return (
        <div>
            <h2>Your Debate Scores</h2>
            <table>
                <thead>
                    <tr>
                        <th>Debate Prompt</th>
                        <th>1-Minute Score</th>
                        <th>3-Minute Score</th>
                        <th>5-Minute Score</th>
                    </tr>
                </thead>
                <tbody>
                    {scores.map((score, index) => (
                        <tr key={index}>
                            <td>{score.prompt}</td>
                            <td>{score.oneMinute || 'N/A'}</td>
                            <td>{score.threeMinute || 'N/A'}</td>
                            <td>{score.fiveMinute || 'N/A'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <h3>Submit New Score</h3>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={newScore.prompt}
                    onChange={(e) => setNewScore({ ...newScore, prompt: e.target.value })}
                    placeholder="Debate Prompt"
                    required
                />
                <input
                    type="number"
                    value={newScore.oneMinute}
                    onChange={(e) => setNewScore({ ...newScore, oneMinute: e.target.value })}
                    placeholder="1-Minute Score"
                />
                <input
                    type="number"
                    value={newScore.threeMinute}
                    onChange={(e) => setNewScore({ ...newScore, threeMinute: e.target.value })}
                    placeholder="3-Minute Score"
                />
                <input
                    type="number"
                    value={newScore.fiveMinute}
                    onChange={(e) => setNewScore({ ...newScore, fiveMinute: e.target.value })}
                    placeholder="5-Minute Score"
                />
                <button type="submit">Submit Score</button>
            </form>
        </div>
    );
};

export default ScoreDashboard;
