import React, { useState } from 'react';
import './LoginModal.css';

const LoginModal = ({ setIsLoginVisible, setToken }) => {
    const [isLoginMode, setIsLoginMode] = useState(true); // Toggle between login and signup
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState(''); // For sign-up

    const handleSubmit = async (e) => {
        e.preventDefault();
        const endpoint = isLoginMode ? '/api/login' : '/api/signup';
        const data = isLoginMode ? { username, password } : { username, password, confirmPassword };

        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error('Failed to authenticate');
            }

            const result = await response.json();
            localStorage.setItem('token', result.token);
            setToken(result.token);
            setIsLoginVisible(false); // Close the modal
        } catch (error) {
            console.error('Error during authentication:', error);
            alert('Authentication failed. Please try again.');
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal">
                <h2>{isLoginMode ? 'Login' : 'Sign Up'}</h2>
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <input
                            type="text"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    {!isLoginMode && (
                        <div className="input-group">
                            <input
                                type="password"
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                        </div>
                    )}
                    <div className="button-group">
                        <button type="submit" className="login-button">
                            {isLoginMode ? 'Login' : 'Sign Up'}
                        </button>
                        <button
                            type="button"
                            className="cancel-button"
                            onClick={() => setIsLoginVisible(false)}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
                <div className="toggle-auth">
                    <p>
                        {isLoginMode ? 'Don\'t have an account?' : 'Already have an account?'}{' '}
                        <span onClick={() => setIsLoginMode(!isLoginMode)}>
                            {isLoginMode ? 'Sign Up' : 'Login'}
                        </span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default LoginModal;
